import React from 'react';
import { FiX } from 'react-icons/fi';

import Flash from './flash';
import Attachments from './attachments';
import Form from './form'

export default class Modal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCollapse = this.handleCollapse.bind(this);
    this.state = {
      active: false,
      collapse: false
    };
  }

  componentDidMount() {
    PubSub.subscribe('MODAL_SHOW', this.handleShow);
    PubSub.subscribe('MODAL_HIDE', this.handleClose);
    PubSub.subscribe('MODAL_COLLAPSE', this.handleCollapse);
  }

  handleShow() {
    this.setState({ active: true });
  }

  handleClose() {
    this.setState({ active: false, collapse: false });
  }

  handleCollapse() {
    this.setState({ collapse: true });
  }

  render() {
    const { token } = this.props;
    const { active, collapse } = this.state;

    return ( 
      <section className={ active ? 'overlay overlay--active' : 'overlay' }>
        <section className="modal">
          <div className="modal__close" onClick={this.handleClose}>
            <FiX />
          </div>
          { !collapse ? <Attachments token={token} /> : null }
          <Flash />
          { !collapse ? <Form token={token} /> : null }
        </section>
      </section>
    )
  }

}