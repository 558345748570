import React from 'react';
import Axios from 'axios';

export default class Form extends React.Component {

  constructor(props) {
    super(props);
    this.handleAttachmentAdd = this.handleAttachmentAdd.bind(this);
    this.handleAttachmentProcessing = this.handleAttachmentProcessing.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      images: [],
      name: '',
      email: '',
      instagram: '',
      loading: false
    };
  }

  componentDidMount() {
    PubSub.subscribe('ATTACHMENT_PROCESSING', this.handleAttachmentProcessing);
    PubSub.subscribe('ATTACHMENT_ADD', this.handleAttachmentAdd);
  }

  handleAttachmentProcessing(msg, data) {
    this.setState({ loading: true });  
  }

  handleAttachmentAdd(msg, data) {
    const newImages = [data.id];
    this.setState({ images: newImages, loading: false });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  async handleSubmit(e) {
    const { token } = this.props;
    const { images, name, email, instagram, loading } = this.state;

    e.preventDefault();
    
    if (!loading) {
      try {      
        const response = await Axios.post('/api/v1/posts', 
          { images, name, email, instagram },
          { headers: {'X-XSRF-TOKEN': token} }
        );
        PubSub.publish('FLASH_SHOW', { message: response.data.message });
        PubSub.publish('MODAL_COLLAPSE');
  
      } catch (error) {
        PubSub.publish('FLASH_SHOW', { error });
      }
    }
  }

  render() {
    const { token } = this.props;
    const { name, email, instagram, loading } = this.state;

    return (      
      <form className="form" onSubmit={this.handleSubmit}>
        <div className="form__field">
          <label>Name</label>
          <input type="text" name="name" placeholder="Your name" value={name} onChange={this.handleChange} />
        </div>
        <div className="form__field">
          <label>Email</label>
          <input type="text" name="email" placeholder="Your email" value={email} onChange={this.handleChange} />
        </div>
        <div className="form__field">
          <label>Instagram</label>
          <input type="text" name="instagram" placeholder="Your instagram nickname" value={instagram} onChange={this.handleChange} />
        </div>
        <div className="form__field">
          <input type="submit" value={ loading ? 'Processing image ...' : 'Submit'} disabled={loading} />
          <small>
            By clicking Submit you agree to our <a href="/terms" target="_blank">Terms</a> and confirm that you have read our <a href="/privacy" target="_blank">Privacy Policy</a>.
          </small>
        </div>
      </form>
    )
  }

}