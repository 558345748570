import React from 'react'
import ReactDOM from 'react-dom'
import Posts from '../../components/application/posts'
import Modal from '../../components/application/modal'

const blocks = [
  { el: document.getElementById('react__posts'), component: Posts },
  { el: document.getElementById('react__modal'), component: Modal },
]

const renderBlock = (block, component) => {

  const Component = component

  if (block) {

    const data = block.dataset
    ReactDOM.render(<Component {...data} />, block)

  }

}

for (let block of blocks) {
  renderBlock(block.el, block.component)
}