import React from 'react';
import Axios from 'axios';
import Dropzone from 'react-dropzone';
import PubSub from 'pubsub-js';
import { FiTrash2 } from 'react-icons/fi';

export default class Attachments extends React.Component {
  constructor(props) {
    super(props);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.state = {
      loading: false,
      item: null
    }
  }

  async handleUpload(files) {
    const self = this;
    const { token } = this.props;
    const formData = new FormData();

    self.setState({ loading: true });

    try {

      // create FormData
      formData.append('_csrf', token);
      formData.append('file', files[0]);
      
      PubSub.publish('ATTACHMENT_PROCESSING');
      const response = await Axios.post('/api/v1/attachments', formData);
      PubSub.publish('ATTACHMENT_ADD', response.data.message);
      self.setState({ item: response.data.message, loading: false })

    } catch (error) {
      self.setState({ loading: false });
      PubSub.publish('FLASH_SHOW', { error })
    }

  }

  async handleRemove(id) {
    const { item } = this.state;

    try {
      await Axios.delete(`/api/v1/attachments/${item.id}`);
      this.setState({ item: null });
    } catch (error) {
      PubSub.publish('FLASH_SHOW', { status: 'error', message: error.response.error })
    }

  }

  render() {
    const { item, loading } = this.state;
    return ( 
      <section className="attachments">
        { item ?
          <div className="attachment">
            <div className="attachment__remove" onClick={this.handleRemove}>
              <FiTrash2 />
            </div>
            <img src={item.links['600x600']} />
          </div> :
          loading ?
          <div className="attachments__uploader">
            <p>
              Processing upload ...
            </p> 
          </div> :
          <Dropzone onDrop={this.handleUpload}>
            {({getRootProps, getInputProps}) => (
              <div className="attachments__uploader" {...getRootProps()}>                
                <input {...getInputProps()} />
                <p>
                  Drag 'n' drop a file here, or click/tap to select
                </p>                
              </div>
            )}
          </Dropzone>         
        }        
      </section>
    )
  }

}