import React from 'react';
import PubSub from 'pubsub-js';

export default class Flash extends React.Component {
  constructor(props) {
    super(props);
    this.handleMessage = this.handleMessage.bind(this);
    this.state = {
      error: null,
      messages: []
    };
  }

  componentDidMount() {
    PubSub.subscribe('FLASH_SHOW', this.handleMessage);
  }

  handleMessage(msg, { message, error }) {
    let newMessages = [];

    if (error) {
      if (error.response.data instanceof Array) {
        newMessages = error.response.data;
      } else if (error.response.data.message) {
        newMessages = [{ message: error.response.data.message }];
      } else {
        newMessages = [{ message: error.response.data.error }]
      }
    } else {
      newMessages = [{ message }];
    }

    this.setState({ messages: newMessages, error });
  }

  render() {
    const { error, messages } = this.state;
    let classes = 'flash';
    classes += error ? ' flash--error' : ' flash--info';

    return messages.length ? ( 
      <section className={classes}>
        {messages.map(item => {
          return <div>{item.message}</div>;
        })}
      </section>
    ) : null;
  }

}