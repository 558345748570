import React from 'react';
import { FiTrash2 } from 'react-icons/fi';

export default class Post extends React.Component {
  constructor(props) {
    super(props);
    this.handleRemove = this.handleRemove.bind(this);
    this.handlePublish = this.handlePublish.bind(this);
  }
  
  handleRemove() {
    const { item } = this.props;
    this.props.onRemove(item.id);
  }

  handlePublish() {
    const { item } = this.props;
    this.props.onPublish(item.id);
  }

  render() {
    const { item, user } = this.props;

    return ( 
      <div className="post">
        {user ? 
          (<div className="post__remove" onClick={this.handleRemove}>
            <FiTrash2 />
          </div>) : null
        }
        <div className="post__image">
          {item.image ? <img src={item.image.links['600x']} /> : <p>No image</p>}          
        </div>
        <a href={`https://instagram.com/${item.instagram}`} target="_blank" className="post__nickname">
          @{item.instagram}
        </a>
        <div className="post__id">
          {user ? `#${item.id} / ${item.email} / ${item.name}` : `#${item.id}`}
        </div>
        {!item.published && user ? 
          (<div className="post__publish" onClick={this.handlePublish}>
            Publish post
          </div>) : null
        }
      </div>
    )
  }

}