import React from 'react';
import Axios from 'axios';
import Masonry from 'react-masonry-component';

import Post from './post'

export default class Posts extends React.Component {
  constructor(props) {
    super(props);
    this.handlePostsLoad = this.handlePostsLoad.bind(this);
    this.handlePostPublish = this.handlePostPublish.bind(this);
    this.handlePostRemove = this.handlePostRemove.bind(this);
    this.state = {
      items: [],
      page: 0,
      lastPage: 1,
      loading: false
    }
  }

  async componentDidMount() {
    this.handlePostsLoad();
  }

  async handlePostsLoad() {
    const { page, lastPage, items } = this.state;

    if (page < lastPage) {
      try {
        const response = await Axios.get('/api/v1/posts', { params: { page: page + 1 }});
        const newItems = [...items, ...response.data.data.posts.data];
        this.setState({ 
          items: newItems, 
          lastPage: response.data.data.posts.lastPage,
          page: page + 1
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  async handlePostPublish(id) {
    const { items } = this.state;

    try {
      await Axios.post(`/api/v1/posts/${id}/publish`);
      const newItems = [...items];
      const item = newItems.find(el => el.id === id);
      item.published = true;
      this.setState({ items: newItems });
    } catch (error) {
      console.log(error);
    }
  }

  async handlePostRemove(id) {
    const { items } = this.state;

    try {
      await Axios.delete(`/api/v1/posts/${id}`);
      const newItems = [...items];
      const itemIndex = newItems.findIndex(el => el.id === id);
      newItems.splice(itemIndex, 1);
      this.setState({ items: newItems });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { user } = this.props;
    const { items, loading, page, lastPage } = this.state;

    return ( 
      <div className="posts">
        <div className="">
          <Masonry
            className='posts__inner'
            options={{ transitionDuration: 0 }} // default {}
          >
            {items.map(item => {
              return <Post 
                key={item.uid} 
                item={item} 
                onRemove={this.handlePostRemove} 
                onPublish={this.handlePostPublish}
                user={user} />;
            })}
          </Masonry>
        </div>
        {(page < lastPage) && items.length ? 
          (<div className="posts__load" onClick={this.handlePostsLoad}>
            Load more
          </div>) : null
        }
      </div>
    )
  }

}